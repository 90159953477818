import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import Card from 'components/Card';
import Loader from 'components/Loader';
import WarningMessage from 'components/WarningMessage';
import CardFooter from 'components/CardFooter';
import Select from 'components/Select';
import Input from 'components/Input';
import FileUploader from 'components/FileUploader';
import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import { loadConfigurations, upsertConfigurations, uploadFile } from '../../store/thunk';

import { availablePrints } from '../resources';

export default function Receipt() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);
  const { urlLogo, pathLogo, print, printNote } = useSelector(state => state.operational.receipt);

  const [receiptPrint, setReceiptPrint] = useState(print || null);
  const [receiptPrintNote, setReceiptPrintNote] = useState(printNote || null);

  const [printNoteLength, setPrintNoteLength] = useState(receiptPrintNote?.length || 0);
  const printNoteMaxLength = 255;

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    setReceiptPrint(print);
    setReceiptPrintNote(printNote);
  }, [print, printNote]);

  const handleUploadLogo = e => {
    const file = e?.target?.files[0];
    const { type, size } = e?.target?.files[0];

    const supportTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    const maxSize = 5242880;

    if(!supportTypes.includes(type)) {
      return toast.error('O formato da imagem não é suportado.');
    }

    if(size > maxSize) {
      return toast.error('O tamanho máximo da imagem não pode exceder 5 MB.');
    }

    dispatch(uploadFile(userId, establishmentId, { file }));
  }

  const handleConfigurationsSave = () => {
    const params = {
      pathLogo,
      print: receiptPrint,
      printNote: receiptPrintNote,
      page: 'Comprovantes'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  return(
    <Card title="Comprovantes">
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Grid xs={12}>
            <Grid container>
              {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
                <WarningMessage
                  message={`Última alteração feita por ${settingHistoryUserName} em ${moment(settingHistoryDateTime).format('LLL')}`}
                />
              )}
              <Grid item xs={12}>
                <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                  <b>Faça o upload do seu logotipo</b>
                </Typography>
                <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                  Imagem utilizada em recibos por email, PDF e orçamentos. Apenas nos formatos: PNG, JPEG e JPG.
                </Typography>
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <div>
                  {!!urlLogo ? (
                    <Grid container>
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        <img src={urlLogo} heigth="72" width="72" alt="Logo" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px', height: '165px', backgroundColor: '#F5F5F5', margin: '10px 0 10px 0' }}>
                        <FontAwesomeIcon
                          size="lg"
                          color="#1E5168"
                          icon={faUpload}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
                <FileUploader
                  fullWidth
                  name="pathLogo"
                  file={urlLogo}
                  onChange={handleUploadLogo}
                >
                  Escolher ficheiro
                </FileUploader>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                <b>Emissão de ticket</b>
              </Typography>
              <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                Escolha como deseja emitir os tickets na entrada e saída dos veículos
              </Typography>
              <Select
                name="print"
                label="Selecione a emissão"
                options={availablePrints}
                value={receiptPrint || ''}
                onChange={e => setReceiptPrint(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                <b>Observação</b>
              </Typography>
              <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                Escreva uma mensagem de observação fixa para as emissões (opcional)
              </Typography>
              <Input
                name="printNote"
                label="Observação na emissão"
                placeholder="Escreva uma mensagem de observação fixa para as emissões (Opcional. Máx: 255 caracteres)"
                style={{ marginLeft: 0 }}
                maxLength={printNoteMaxLength}
                value={receiptPrintNote || ''}
                onChange={e => {
                  setReceiptPrintNote(e.target.value);
                  setPrintNoteLength(e.target.value.length);
                }}
              />
              <Typography color="textSecondary">
                Caracteres: {printNoteLength}/{printNoteMaxLength}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <CardFooter>
              <Button
                color="success"
                onClick={handleConfigurationsSave}
              >
                Salvar
              </Button>
            </CardFooter>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}