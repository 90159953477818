import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { concat, map, set, lensProp } from 'ramda';

import useDidMount from 'helpers/hooks/useDidMount';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Resume from './Resume';
import ServiceOrder from '../../../components/ServiceOrder/components';
import MTable from '../../../components/MTable';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import { toggleServiceOrder } from '../store/actions';
import { clearSearch, persistQueryParamsSearch } from 'components/Filter/store/actions';
import { setServiceOrderId, clearChanges } from '../../../components/ServiceOrder/store/actions';

import { loadFilters } from '../../../components/Filter/store/thunk';
import { loadServiceOrders, loadResumeOrders, loadExport } from '../store/thunk';

import { formatFilters, formatServiceOrders } from '../../../helpers/formatters';
import { convertToBrl } from 'helpers/converters';
import { inputs } from './resources';

import './styles.css';

const headers = [
  { title: 'Cliente', field: 'clientName' },
  { title: 'Placa', field: 'plate' },
  { title: 'Permanência', field: 'permanence' },
  { title: 'Meio de pagamento', field: 'paymentMethodName' },
  { title: 'Valor total', field: 'totalAmount' },
  { title: 'Situação operacional', field: 'operationSituationName' },
  { title: 'Situação financeira', field: 'financialSituationName' }
];

export default function OperationalHistory() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const didComponentMount = useDidMount();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { selects, qp } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { serviceOrders, modal, total, resume, isLoading, isResumeLoading } = useSelector(state => state.operationalHistory);

  const formattedServiceOrders = serviceOrders.map(serviceOrder => {
    return {
      ...serviceOrder,
      totalAmount: convertToBrl(+serviceOrder?.totalAmount)
    }
  });

  const filters = concat(
    concat(inputs, formatFilters(['typeDateTime'], selects)),
    formatFilters(
      [
        'contract',
        'clientType',
        'user',
        'typePrice',
        'services',
        'forFreeSituation',
        !isMobile && 'operationSituation',
        !isMobile && 'financialSituation',
        'paymentMethod',
        !establishmentId ? 'primitivePaymentMethod' : ''
      ],
      selects
    )
  )
  ?.map(filter => {
    if(filter?.id == 'contract') {
      return {
        ...filter,
        advanced: isMobile,
      }
    }

    if(filter?.id == 'startTime') {
      return {
        ...filter,
        advanced: isMobile
      }
    }

    if(filter?.id == 'endTime') {
      return {
        ...filter,
        advanced: isMobile
      }
    }

    if(filter?.id == 'discount') {
      return {
        ...filter,
        advanced: isMobile
      }
    }

    if(filter?.id == 'services') {
      return {
        ...filter,
        options: filter?.options?.map(option => {
          return option?.value == '' ? {
            ...option,
            label: '--'
          } : {
            label: option?.description,
            value: option?.serviceId
          }
        })
      }
    }

    if(filter?.id == 'forFreeSituation') {
      return {
        ...filter,
        options: filter?.options?.map(option => {
          return {
            ...option,
            value: option?.id
          }
        })
      }
    }

    return filter;
  })
  ?.map(filter => {
    if(filter?.id == 'clientName' || filter?.id == 'card' || filter?.id == 'serviceOrderPlate') {
      return {
        ...filter,
        order: 1
      }
    }

    if(filter?.id == 'financialSituation' || filter?.id == 'operationSituation') {
      return {
        ...filter,
        order: 2
      }
    }

    if(filter?.id == 'forFreeSituation') {
      return {
        ...filter,
        order: 5
      }
    }

    if(filter?.id == 'user') {
      return {
        ...filter,
        order: 3
      }
    }

    return {
      ...filter,
      order: 4
    }
  })
  ?.sort((a, b) => a.order - b.order)
  ?.map(filter => {
    if(isMobile) {
      if(!establishmentId) {
        if(filter?.id == 'rpsNumber') {
          return {}
        }

        if(filter?.id == 'nfseNumber') {
          return {}
        }

        if(filter?.id == 'typePrice') {
          return {}
        }

        if(filter?.id == 'services') {
          return {}
        }

        if(filter?.id == 'paymentMethod') {
          return {}
        }
      }
    }

    return filter;
  })
  ?.filter(filters => Object.keys(filters).length > 0);

  const [operationalHistoryPage, setOperationalHistoryPage] = useState(1);
  const operationalHistoryPageSize = 10;
  const operationalHistoryPageCount = Math.ceil(total / operationalHistoryPageSize);

  const removeFinancialStates = filterOption => {
    const { id, options } = filterOption;

    let formattedFilter = filterOption;

    if(id === 'financialSituation') {
      const filterReconciled = options
        ?.filter(opt => +opt.value !== 1)
        ?.filter(opt => +opt.value !== 4);

      formattedFilter = set(lensProp('options'), filterReconciled, filterOption);
    }

    return formattedFilter;
  }

  const formattedFilters = map(removeFinancialStates, filters);

  const headerFilters = concat([], formatFilters(['operationSituation', 'financialSituation'], selects));
  const formattedHeaderFilters = map(removeFinancialStates, headerFilters);

  const handleServiceOrdersAndResume = ({ page, qp, exportation }) => {
    const extraProps = {
      startDate,
      endDate,
      exportation,
      userId,
      establishmentId
    }

    const formattedQp = qp?.replace(/[^=&]+=(&|$)/g, "").replace(/&$/, "");

    return dispatch(loadServiceOrders({ page, pageSize: operationalHistoryPageSize, qp: formattedQp, extraProps }))
      .then(() => {
        const extraProps = {
          startDate,
          endDate
        }

        const formattedQp = qp?.replace(/[^=&]+=(&|$)/g, "").replace(/&$/, "");

        dispatch(loadResumeOrders(userId, establishmentId, formattedQp, extraProps));
      });
  }

  const handleExportNew = () => {
    const extraProps = {
      startDate,
      endDate,
      page: operationalHistoryPage,
      pageSize: operationalHistoryPageSize,
      qp: qp?.replace('forFreeSituation', 'forFreeSituationId')
    }

    return dispatch(loadExport(userId, establishmentId, extraProps));
  }

  useEffect(() => {
    dispatch(clearChanges());
    dispatch(clearSearch());
    dispatch(persistQueryParamsSearch(''));
  }, []);

  useEffect(() => {
    handleServiceOrdersAndResume({ page: 1, qp: '', exportation: false });
    dispatch(loadFilters(userId, establishmentId));
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount) {
      handleServiceOrdersAndResume({ page: operationalHistoryPage, qp, exportation: false });
    }
  }, [operationalHistoryPage]);

  const handleSelectServiceOrder = item => {
    const { serviceOrderId, establishmentId } = item;
    dispatch(setServiceOrderId(serviceOrderId, userId, establishmentId));
    dispatch(toggleServiceOrder(true));
  }

  const verifyFinancialSituation = row => {
    const styles = { backgroundColor: '#FFFFFF' }

    if(row) {
      const { financialSituationId, situationId } = row;

      if(financialSituationId === 2 || financialSituationId === 3) {
        styles.backgroundColor = '#dff0d8';
      }

      if(situationId === 3) {
        styles.backgroundColor = '#f2dede';
      }
    }

    return styles;
  }

  return(
    <Paper>
      <Resume
        title="Resumo operacional"
        resume={resume}
        isLoading={isResumeLoading}
      />
      {isMobile ? (
        <FilterMobile
          filter
          alternativeExport
          functionAlternativeExport={handleExportNew}
          hasSearchTerm
          exportType="operationalExport"
          fileName="Histórico Operacional"
          headerFilters={formattedHeaderFilters}
          filters={formattedFilters}
          handlePageRequest={({ page, qp }) => handleServiceOrdersAndResume(({ page, qp, exportation: false }))}
          formatComponentData={formatServiceOrders}
        />
      ) : (
        <Filter
          filter
          alternativeExport
          functionAlternativeExport={handleExportNew}
          hasSearchTerm
          exportType="operationalExport"
          fileName="Histórico Operacional"
          filters={formattedFilters}
          handlePageRequest={({ page, qp }) => handleServiceOrdersAndResume(({ page, qp, exportation: false }))}
          formatComponentData={formatServiceOrders}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={formattedServiceOrders}
        rowStyle={verifyFinancialSituation}
        selectRow={handleSelectServiceOrder}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={operationalHistoryPageCount}
          page={operationalHistoryPage}
          onChange={(_, page) => setOperationalHistoryPage(page)}
        />
      </Grid>
      {modal ? <ServiceOrder pageOrigin="operational-history" /> : null}
    </Paper>
  );
}