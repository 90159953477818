import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'qs';

import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { login } from '../store/thunk';
import { handleLogout } from '../store/actions';
import { clearProfile } from '../../Profile/store/actions';
import { clearBusinessInfo } from '../../BusinessInfo/store/actions';

import { validations } from './validations';

import { useStyles } from './styles';
import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

const logoJumpPark = require('../../../assets/img/logo_login.svg');
const logoJumpCar = require('../../../assets/img/logo_login_jump_car.svg');
const backgroundJumpPark = require('../../../assets/img/login_background.png');
const backgroundJumpCar = require('../../../assets/img/login_background_jump_car.png');

const Login = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { width, height } = useWindowDimensions();
  const isMobile = width < 960;

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const { loggedIn, establishments } = useSelector(state => state.login);

  const initialState = {
    email: '',
    password: ''
  }

  const isInvoiceLoginUrl = location.pathname == '/area-cliente';

  const doesUserHaveAnyEstablishment =
    establishments[0]?.establishmentId || establishments[establishments.length - 1]?.establishmentId;

  const isJumpPark =
    window.location.hostname == 'localhost' ||
    window.location.hostname == 'teste-admin.jumppark.com.br' ||
    window.location.hostname == 'admin.jumppark.com.br';

  useEffect(() => {
    const notAuthorized = qs.parse(location.search, { ignoreQueryPrefix: true }).notauthorized;

    if(notAuthorized) {
      dispatch(handleLogout());
      toast.error('Ação não autorizada. Por favor, efetue o login antes de continuar.');
    }

    if(loggedIn && isInvoiceLoginUrl) {
      return history.push('/cobranca/boletos');
    }

    if(loggedIn && doesUserHaveAnyEstablishment) {
      return history.push('/visao-geral');
    }
  }, []);

  const handleLogin = values => {
    setLoading(true);

    dispatch(clearProfile())

    dispatch(login(values, history))
      .finally(() => setLoading(false));
  }

  const handleRegistration = () => {
    dispatch(clearProfile());
    dispatch(clearBusinessInfo());

    localStorage.clear();
  }

  const getSloganTop = () => {
    if(height < 600) {
      return '15%';
    }

    if(height < 700) {
      return '30%';
    }

    if(height < 850) {
      return '40%';
    }

    return '50%';
  }

  return(
    <Grid container className={classes.wrapper}>
      {!isMobile && (
        <div
          style={{ top: getSloganTop() }}
          className={classes.slogan}
        >
          <Typography variant="h5">
            {isJumpPark ? (
              <b>Controle o hoje, conquiste o <br /> amanhã_</b>
            ) : (
              <b>Alcance resultados, supere <br /> expectativas_</b>
            )}
          </Typography>
          <div className={classes.sloganVerticalAdornment} />
          <div className={classes.sloganHorizontalAdornment} />
        </div>
      )}
      <Grid
        item
        md={6}
        xs={12}
        style={{ backgroundImage: `url(${isJumpPark ? backgroundJumpPark : backgroundJumpCar})` }}
        className={[classes.containerBackground, isMobile ? classes.halfVh : classes.fullVh]}
      >
        <Grid className={isJumpPark ? classes.containerBackgroundJumpParkGradient : classes.containerBackgroundJumpCarGradient} />
      </Grid>
      {!isMobile && (
        <Grid
          item
          md={3}
          xs={12}
          className={[classes.containerSecondary, isMobile ? classes.halfVh : classes.fullVh]}
        >
          <Typography
            color="#212121"
            variant="h5"
            style={{ whiteSpace: 'nowrap' }}
            className={classes.containerFloatingComponent}
          >
            Juntos nós podemos
          </Typography>
        </Grid>
      )}
      <Grid
        item
        md={3}
        xs={12}
        className={[classes.containerLogin, !isMobile && classes.fullVh]}
      >
        <div className={isMobile && classes.containerFormAndSloganMobile}>
          <div
            className={isMobile ? classes.containerFormMobile : classes.containerForm}
            style={{ padding: 30 }}
          >
            <img
              alt="Jump Park"
              src={isJumpPark ? logoJumpPark : logoJumpCar}
              className={classes.logo}
            />
            <Formik
              enableReinitialize
              initialValues={initialState}
              validationSchema={validations}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleLogin}
            >
              {({ ...formikProps }) => (
                <form
                  onKeyDown={event => {
                    if(event.key == 'Enter') {
                      return handleLogin(formikProps.values);
                    }
                  }}
                >
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    value={formikProps.values.email}
                    error={formikProps.errors.email}
                    helperText={formikProps.errors.email}
                    onChange={formikProps.handleChange}
                  />
                  <Input
                    name="password"
                    type="password"
                    label="Senha"
                    value={formikProps.values.password}
                    error={formikProps.errors.password}
                    helperText={formikProps.errors.password}
                    onChange={formikProps.handleChange}
                  />
                  <Button
                    fullWidth
                    verticalGutter
                    type="button"
                    color="#022A5C"
                    loading={loading}
                    onClick={() => handleLogin(formikProps.values)}
                  >
                    Entrar
                  </Button>
                </form>
              )}
            </Formik>
            <Grid>
              {!isInvoiceLoginUrl && (
                <Typography
                  to="/esqueci-a-senha"
                  variant="body1"
                  color="textSecondary"
                  component={Link}
                  onClick={() => handleRegistration()}
                >
                  Esqueci minha senha
                </Typography>
              )}
              {isMobile && (
                <Typography color="textSecondary" style={{ margin: '30px 0 30px 0' }}>
                  <b>Juntos nós podemos</b>
                </Typography>
              )}
              {isMobile && (
                <>
                  {!isInvoiceLoginUrl && (
                    <Link
                      to={{
                        pathname: "/cadastro-inicial",
                        state: {
                          isRegister: true
                        }
                      }}
                      onClick={() => handleRegistration()}
                    >
                      <Button
                        fullWidth
                        color="#FF7D34"
                        style={{ whiteSpace: 'nowrap', padding: 15 }}
                      >
                        Experimente por 7 dias
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </Grid>
          </div>
          {isMobile && (
            <div className={classes.sloganMobile}>
              <Typography variant="h5">
                {isJumpPark ? (
                  <b>Controle o hoje, conquiste o <br /> amanhã_</b>
                ) : (
                  <b>Alcance resultados, supere <br /> expectativas_</b>
                )}
              </Typography>
            </div>
          )}
        </div>
        {!isMobile && (
          <>
            {!isInvoiceLoginUrl && (
              <div
                className={classes.containerFloatingComponent}
                style={{ width: '100%', padding: '0 30px 0 30px' }}
              >
                <Link
                  to={{
                    pathname: "/cadastro-inicial",
                    state: {
                      isRegister: true
                    }
                  }}
                  onClick={() => handleRegistration()}
                >
                  <Button
                    fullWidth
                    color="#FF7D34"
                    style={{ whiteSpace: 'nowrap', padding: 15, marginBottom: -15 }}
                  >
                    Experimente por 7 dias
                  </Button>
                </Link>
              </div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Login;