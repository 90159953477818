import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import Card from 'components/Card';
import Select from 'components/Select';
import Button from 'components/Button';

const iconStore = require('../../../assets/img/icon_product_store.svg');
const iconSell = require('../../../assets/img/icon_product_sell.svg');
const iconFoods = require('../../../assets/img/icon_product_foods.svg');
const iconOthers = require('../../../assets/img/icon_product_others.svg');
const iconAutomotiveParts = require('../../../assets/img/icon_product_automotive_parts.svg');

export default function ProductsTypeSelection() {
  const history = useHistory();

  const productObjetiveOptions = [
    {
      value: '0',
      label: 'Produto para venda e consumo interno',
      description: 'Se o mesmo produto for para venda aos clientes e consumo interno através dos serviços prestados.',
      icon: '',
      onClick: () => history.push('/novo-produto-consumo-e-venda')
    },
    {
      value: '1',
      label: 'Produto apenas para venda',
      description: 'Produto que possui a finalidade apenas para venda.',
      icon: iconSell,
      onClick: () => history.push('/novo-produto-venda')
    },
    {
      value: '2',
      label: 'Produto apenas para consumo',
      description: 'Se você vai usar este produto apenas para consumo interno nas prestações de serviço.',
      icon: iconStore,
      onClick: () => history.push('/novo-produto')
    },
    {
      value: '3',
      label: 'Alimentos',
      description: 'Alimentos que serão vendidos dentro do estabelecimento.',
      icon: iconFoods,
      onClick: () => history.push('/novo-produto-venda')
    },
    {
      value: '4',
      label: 'Outros',
      description: 'Diversos produtos sem categoria definida.',
      icon: iconOthers,
      onClick: () => history.push('/novo-produto-venda')
    },
    {
      value: '5',
      label: 'Peças automotivas',
      description: 'Peças de veículos que são vendidas separadas ou dentro da ordem de serviço.',
      icon: iconAutomotiveParts,
      onClick: () => history.push('/novo-produto-venda')
    }
  ];

  const [productObjetive, setProductObjetive] = useState(productObjetiveOptions[0]);

  return(
    <Grid container justify="center">
      <Grid xs={12} md={6}>
        <Card title="Qual o objetivo do produto?">
          <Select
            name="productObjetive"
            options={productObjetiveOptions}
            value={productObjetive.value}
            onChange={event => setProductObjetive(productObjetiveOptions[event.target.value])}
          />
          <Grid container>
            <Grid xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
              {(+productObjetive.value == 0) ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={iconStore} />
                  <Typography color='primary' style={{ margin: '0 5px 0 5px' }}>
                    <b>+</b>
                  </Typography>
                  <img src={iconSell} style={{ marginRight: 10 }} />
                </div>
              ) : (
                <img src={productObjetive.icon} />
              )}
              <Typography variant='body1' color='primary'>
                <b>{productObjetive.label}</b>
              </Typography>
              <Typography variant='body1' color='primary' style={{ margin: '20px 0 20px 0' }}>
                {productObjetive.description}
              </Typography>
              <Button
                type="button"
                color="success"
                onClick={productObjetive.onClick}
              >
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}