import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { concat } from 'ramda';

import { Paper, Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MTable from '../../../components/MTable';
import ModalDrawerSwitcher from '../../../components/ModalDrawerSwitcher';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Button from '../../../components/Button';
import Invoice from '../../../components/Invoice';
import InvoicePos from '../../../components/InvoicePos';
import { List } from '../../../components/ExportPDF/templates';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen, faFileAlt, faFileInvoiceDollar, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { persistQueryParamsSearch } from 'components/Filter/store/actions';
import { selectClient } from '../store/actions';
import { requestLoadClients, requestClientExportation } from '../store/thunk';
import { clearBasicInfo, setClientId, setIsEdit } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setEstablishmentId } from '../../BusinessInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../../ClientInvoices/store/actions';

import { formatFilters, formatterClients } from '../../../helpers/formatters';
import { convertList, convertToEstablishmentName } from '../../../helpers/converters';
import { createPDF } from '../../../helpers/common';
import { setBaseAPI } from '../../../services';
import { inputs } from './resources';

import { useStyles } from './styles';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';
import useDidMount from 'helpers/hooks/useDidMount';

import { SITE_RESTRICTIONS } from '../../../helpers/restrictions';

const iconAvulso = require('../../../assets/img/icon_avulso.svg');
const iconMensalista = require('../../../assets/img/icon_mensalista.svg');
const iconPosPago = require('../../../assets/img/icon_pos_pago.svg');

const headers = [
  { title: 'Nome', field: 'name', sortable: false },
  { title: 'E-mail', field: 'email', sortable: false },
  { title: 'Telefone', field: 'phones', sortable: false },
  { title: 'Situação da fatura', field: 'clientInvoiceSituation', sortable: false },
  { title: 'Data de vencimento', field: 'dueDateTime', sortable: false }
];

const exportHeaders = [
  { label: 'Nome', id: 'name' },
  { label: 'E-mail', id: 'email' },
  { label: 'Telefone', id: 'phones' },
  { label: 'Situação da fatura', id: 'clientInvoiceSituation' },
  { label: 'Data de vencimento', id: 'dueDateTime' },
  { label: 'Valor da fatura', id: 'invoiceAmount' },
  { label: 'Veículos', id: 'vehicles' },
  { label: 'Vagas', id: 'vacancies' }
];

export default function ClientList() {
  const dispatch = useDispatch();

  const history = useHistory();
  const { search } = useLocation();
  const locationParams = new URLSearchParams(search);

  const isNewClient = locationParams.get('newClient');

  const classes = useStyles();

  const didComponentMount = useDidMount();

  const { width } = useWindowDimensions();
  const isMobile = width < 962;
  const tableEditActionWidth = width < 768 ? 100 : 80;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishmentTypes } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const isUpdatingInvoice = useSelector(state => state.clientInvoices.isUpdatingInvoice);
  const { clients: fetchClients, total, isLoading } = useSelector(state => state.clientList);
  const { selects, qp, isFilterButtonClicked, isClientExportationError } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const userRestrictions = useSelector(state => state.profile.userRestrictions);

  const [isClientSelectionDrawerOpen, setIsClientSelectionDrawerOpen] = useState(isNewClient);
  const [clientSelectionOptions, setClientSelectionOptions] = useState([]);
  const [selectedClientTypeId, setSelectedClientTypeId] = useState(null);

  const [selectedInvoiceEstablishmentId, setSelectedInvoiceEstablishmentId] = useState(null);
  const [isClientExportationLoading, setIsClientExportationLoading] = useState(false);

  const defaultEstablishmentTypes = establishmentTypes || [];

  const establishmentName =
    convertToEstablishmentName(establishmentId, establishments) ||
    'Todos os estabelecimentos';

  const dateRange = [startDate, endDate];

  const isCarWashOnly = defaultEstablishmentTypes.length == 1 && +defaultEstablishmentTypes[0] == 2;
  const isStoreOnly = defaultEstablishmentTypes.length == 1 && +defaultEstablishmentTypes[0] == 3;
  const isCarWashAndNotParking = defaultEstablishmentTypes.includes(2) && !defaultEstablishmentTypes.includes(1);

  useEffect(() => {
    dispatch(setIsEdit(false));

    const clientAvulso = {
      label: 'Cliente avulso',
      value: 1,
      icon: iconAvulso,
      description: 'Cliente que pode receber restrições ou automações. Não possuem faturas.',
      details: 'Aplica-se para Condôminios, amigos com desconto específico, clientes com cobrança automática.'
    }

    const clientMensalista = {
      label: 'Mensalista',
      value: 2,
      icon: iconMensalista,
      description: 'Cliente mensalista, mas também pode criar cobranças quinzenais ou semanais.',
      details: 'Aplica-se para clientes que pagam um valor fixo todo mês, mensalistas. Ou clientes que pagam um valor fixo a cada quinzena ou semana.'
    }

    const clientPosPago = {
      label: 'Cliente pós pago',
      value: 3,
      icon: iconPosPago,
      description: 'Clientes com faturas acumulativas e com fechamento de fatura antes do pagamento da fatura.',
      details: 'Aplica-se para clientes como empresas ou pessoas, que pagam após uso dos serviços e acumulam um saldo em uma fatura. Após o pagamento das faturas, as OS mudam o estado financeiro de "A Faturar" para "Faturada". E o dinheiro entra na empresa apenas após o pagamento da fatura.'
    }

    const clientPosPagoDeEstetica = {
      label: 'Cliente pós pago de estética',
      value: 5,
      icon: iconPosPago,
      description: 'Clientes com faturas acumulativas e com fechamento de fatura antes do pagamento da fatura.',
      details: 'Aplica-se para clientes como empresas ou pessoas, que pagam após uso dos serviços e acumulam um saldo em uma fatura. Após o pagamento das faturas, as OS mudam o estado financeiro de "A Faturar" para "Faturada". E o dinheiro entra na empresa apenas após o pagamento da fatura.'
    }

    if(isCarWashOnly) {
      const initialOptions = [
        clientAvulso,
        {
          label: 'Cliente pós pago',
          value: 5,
          description: 'Clientes com faturas acumulativas e com fechamento de fatura antes do pagamento da fatura.',
          details: 'Aplica-se para clientes como empresas ou pessoas, que pagam após uso dos serviços e acumulam um saldo em uma fatura. Após o pagamento das faturas, as OS mudam o estado financeiro de "A Faturar" para "Faturada". E o dinheiro entra na empresa apenas após o pagamento da fatura.'
        }
      ];

      return setClientSelectionOptions(initialOptions);
    }

    if(isStoreOnly) {
      const initialOptions = [
        clientAvulso
      ];

      return setClientSelectionOptions(initialOptions);
    }

    if(isCarWashAndNotParking) {
      const initialOptions = [
        clientAvulso,
        clientPosPagoDeEstetica
      ];

      return setClientSelectionOptions(initialOptions);
    }

    if(establishmentTypes?.includes(1)) {
      const initialOptions = [
        clientAvulso,
        clientMensalista,
        clientPosPago
      ];

      return setClientSelectionOptions(initialOptions);
    }

    const initialOptions = [
      clientAvulso,
      clientMensalista,
      clientPosPago,
      clientPosPagoDeEstetica
    ];

    setClientSelectionOptions(initialOptions);
  }, [userId, establishmentId]);

  useEffect(() => {
    if(isClientExportationError) {
      setIsClientExportationLoading(true);

      handleClientExportation(userId, establishmentId, qp)
        .then(res => {
          const exportData = convertList(
            ['category', 'clientInvoiceSituation'],
            formatFilters(['category', 'clientInvoiceSituation'], selects),
            formatterClients(res?.content)
          );

          const component = pdf(
            <List
              exportResume={null}
              exportHeaders={establishmentId ? exportHeaders : [{ label: 'Estabelecimento', id: 'establishmentName' }, ...exportHeaders]}
              exportData={exportData}
              dateRange={dateRange}
              fileName="clientes"
              establishmentName={establishmentName}
            />
          );

          return createPDF(component);
        })
        .catch(() => toast.error('Não foi possível exportar, tente novamente mais tarde'))
        .finally(() => setIsClientExportationLoading(false));
    }
  }, [isClientExportationError]);

  const clientTypeFilterValue = () => {
    if(isCarWashOnly) {
      return [
        {
          label: '--',
          value: ''
        },
        {
          label: 'Cliente avulso',
          value: 1
        },
        {
          label: 'Cliente pós pago',
          value: 5
        },
      ];
    }

    return [
      {
        label: '--',
        value: ''
      },
      {
        label: 'Cliente avulso',
        value: 1
      },
      {
        label: 'Mensalista',
        value: 2
      },
      {
        label: 'Cliente pós pago',
        value: 4
      }
    ];
  }

  const filters = concat(
    formatFilters(['clientType', 'clientInvoiceSituation', 'status', 'card'], selects), inputs)
    .map(filter => {
      return filter?.id == 'clientType'
        ? {
          ...filter,
          options: clientTypeFilterValue()
        }
        : filter
    });

  const clients = convertList(['clientInvoiceSituation'], filters, fetchClients);

  const [isOpenInvoicePos, setIsOpenInvoicePos] = useState(false);

  const [clientsPage, setClientsPage] = useState(1);
  const clientsPageSize = 10;
  const clientsPageCount = Math.ceil(total / clientsPageSize);

  const handleLoadClients = ({ page, qp }) => {
    return dispatch(
      requestLoadClients({ userId, establishmentId, page, pageSize: clientsPageSize, qp })
    );
  }

  const handleClientExportation = (userId, establishmentId, qp) => {
    return dispatch(requestClientExportation(userId, establishmentId, qp));
  }

  useEffect(() => {
    dispatch(persistQueryParamsSearch(''));
  }, []);

  useEffect(() => {
    if(didComponentMount) {
      handleLoadClients({ page: 1, qp: '' });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount && !isFilterButtonClicked) {
      handleLoadClients({ page: clientsPage, qp: qp == '' ? '' : qp });
    }
  }, [qp]);

  useEffect(() => {
    if(!didComponentMount) {
      handleLoadClients({ page: clientsPage, qp: qp == '' ? '' : qp });
    }
  }, [clientsPage, establishmentId]);

  useEffect(() => {
    if(establishmentId) {
      handleLoadClients({ page: clientsPage, qp: qp == '' ? '' : qp });
    }
  }, [establishmentId]);

  const handleGoToEdit = client => {
    const { clientTypeId, clientId, establishmentId } = client;

    dispatch(setIsEdit(true));
    dispatch(selectClient(clientTypeId));
    dispatch(setClientId(clientId));
    dispatch(setEstablishmentId({ establishmentId }));
    setBaseAPI(userId, establishmentId);

    history.push({
      pathname: '/cadastro-de-cliente/informacoes-basicas/',
      state: {
        data: {
          userId,
          establishmentId,
          clientId,
          isEdit: true
        }
      }
    });
  }

  const handleGoToInvoices = async client => {
    const { clientId, establishmentId } = client;

    await dispatch(setClientId(clientId));
    await dispatch(setEstablishmentId({ establishmentId }));
    await setBaseAPI(userId, establishmentId);
    await history.push('/faturas');
  }

  const handleGoToLastInvoice = async client => {
    const { clientId, establishmentId, invoiceDateTime } = client;

    if(!invoiceDateTime) {
      return toast.error('Nenhuma fatura encontrada.');
    }

    setSelectedInvoiceEstablishmentId(establishmentId);

    await dispatch(setClientId(clientId));
    await dispatch(setInvoiceId(invoiceDateTime));
    await setBaseAPI(userId, establishmentId);

    if(client.clientTypeId === 4 || client.clientTypeId === 5) {
      setIsOpenInvoicePos(true);
      return;
    }

    await dispatch(handleUpdateInvoice(true));
  }

  const checkClientType = row => {
    const styles = { backgroundColor: 'none' };

    if(row) {
      const { clientTypeId } = row;

      if(clientTypeId === 1) {
        styles.backgroundColor = '#f8f9fa';
      }
    }

    return styles;
  }

  const verifyTypeClient = rowData => {
    if(rowData.clientTypeId === 5) {
      return false;
    }

    if(rowData.clientTypeId === 4) {
      return false;
    }

    if(rowData.clientTypeId === 2) {
      return false;
    }

    return true;
  }

  return(
    <Paper>
      {isClientSelectionDrawerOpen && (
        <ModalDrawerSwitcher
          id='client-selection-drawer'
          title='Adicionar cliente'
          open={isClientSelectionDrawerOpen}
          onClose={() => setIsClientSelectionDrawerOpen(false)}
        >
          <Grid container>
            <Grid xs={12}>
              <Typography color='primary' style={{ borderBottom: '1px solid #E4E7EA' }}>
                <b>Selecione o tipo de cliente</b>
              </Typography>
            </Grid>
            {clientSelectionOptions.map(client => {
              return(
                <Grid xs={12} style={{
                  backgroundColor: +client.value == +selectedClientTypeId ? 'rgba(42, 93, 157, 0.1)' : '#FCFCFC',
                  border: +client.value == +selectedClientTypeId ? '1px solid #022A5C' : '1px solid #D7D7D7',
                  borderRadius: 5,
                  padding: 10,
                  marginTop: 10,
                  marginBottom: 10
                }}>
                  <Grid container>
                    <Grid xs={12} style={{ borderBottom: '1px solid #E4E7EA', paddingBottom: 10, marginBottom: 10 }}>
                      <Grid container>
                        <Grid xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={client.icon} />
                        </Grid>
                        <Grid xs={10}>
                          <Typography color='textSecondary'>
                            <b>{client.label}</b>
                          </Typography>
                          <Typography>{client.description}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} style={{ borderBottom: '1px solid #E4E7EA', paddingBottom: 10, marginBottom: 10 }}>
                      <Grid container>
                        <Grid xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            color="#757575"
                            size="2x"
                          />
                        </Grid>
                        <Grid xs={11} style={{ padding: '10px 0 10px 0' }}>
                          <Typography variant='body2'>{client.details}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        color='primary'
                        onClick={() => setSelectedClientTypeId(client.value)}
                      >
                        Selecionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            {!!selectedClientTypeId && (
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginBottom: 10 }}>
                <Button
                  color='tertiary'
                  variant='outlined'
                  onClick={() => setSelectedClientTypeId(null)}
                >
                  Voltar
                </Button>
                <Button
                  color='primary'
                  onClick={() => {
                    dispatch(clearBasicInfo());
                    dispatch(selectClient(parseInt(selectedClientTypeId, 10)));
                    return history.push('/cadastro-de-cliente/informacoes-basicas/');
                  }}
                >
                  Cadastrar
                </Button>
              </Grid>
            )}
          </Grid>
        </ModalDrawerSwitcher>
      )}
      {isMobile ? (
        <FilterMobile
          filter
          alternativeExport
          functionAlternativeExport={() => handleClientExportation(userId, establishmentId, qp)}
          hasSearchTerm
          filters={filters}
          handlePageRequest={handleLoadClients}
          exportType="clients"
          fileName="clientes"
          exportHeaders={establishmentId ? exportHeaders : [{ label: 'Estabelecimento', id: 'establishmentName' }, ...exportHeaders]}
          formatComponentData={formatterClients}
          actionButton={
            <Button
              type="button"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#FFFFFF"
                  size="xs"
                />
              }
              onClick={() => setIsClientSelectionDrawerOpen(true)}
            >
              Adicionar Cliente
            </Button>
          }
        />
      ) : (
        <Filter
          filter
          alternativeExport
          functionAlternativeExport={() => handleClientExportation(userId, establishmentId, qp)}
          hasSearchTerm
          filters={filters}
          handlePageRequest={handleLoadClients}
          exportType="clients"
          fileName="clientes"
          exportHeaders={establishmentId ? exportHeaders : [{ label: 'Estabelecimento', id: 'establishmentName' }, ...exportHeaders]}
          formatComponentData={formatterClients}
          actionButton={
            <Button
              type="button"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  color="#FFFFFF"
                  size="xs"
                />
              }
              onClick={() => setIsClientSelectionDrawerOpen(true)}
            >
              Adicionar Cliente
            </Button>
          }
        />
      )}
      <div className={classes.table}>
        <MTable
          loading={isLoading || isClientExportationLoading}
          headers={headers}
          data={clients}
          actions={[
            rowData => ({
              icon: () => (
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  color="#022A5C"
                  size="xs"
                />
              ),
              tooltip: 'Fatura pendente',
              onClick: (event, rowData) => handleGoToLastInvoice(rowData),
              hidden: verifyTypeClient(rowData)
            }),
            rowData => ({
              icon: () => (
                <FontAwesomeIcon
                  icon={faFileAlt}
                  color="#022A5C"
                  size="xs"
                />
              ),
              tooltip: 'Lista de faturas',
              onClick: (event, rowData) => handleGoToInvoices(rowData),
              hidden: verifyTypeClient(rowData)
            }),
            !userRestrictions?.includes(SITE_RESTRICTIONS.EDIT_CLIENT) && {
              icon: () => (
                <FontAwesomeIcon
                  icon={faPen}
                  color="#022A5C"
                  size="xs"
                />
              ),
              tooltip: 'Editar cliente',
              onClick: (event, rowData) => handleGoToEdit(rowData)
            }
          ]}
          actionColumnWidth={tableEditActionWidth}
          rowStyle={checkClientType}
        />
      </div>
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={clientsPageCount}
          page={clientsPage}
          onChange={(_, page) => setClientsPage(page)}
        />
      </Grid>
      {isUpdatingInvoice && (
        <Invoice
          isOpen={isUpdatingInvoice}
          establishmentId={selectedInvoiceEstablishmentId}
          pageOrigin="clients"
        />
      )}
      {isOpenInvoicePos && (
        <InvoicePos
          isOpen={isOpenInvoicePos}
          setIsOpenInvoicePos={setIsOpenInvoicePos}
          establishmentId={selectedInvoiceEstablishmentId}
        />
      )}
    </Paper>
  );
}